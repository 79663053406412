<template>
  <div>
    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-row v-if="!loadingError">
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group label="Buyers">
          <custom-v-select
            v-model="buyers"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="buyerOptions"
            label="text"
            placeholder="Select Buyers"
            multiple
            :disabled="loading"
            :close-on-select="false"
            :reduce="data => data.id"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Bill Date"
        >
          <flat-picker
            v-model="billDate"
            placeholder="Select Date Range"
            :config="{ mode: 'range'}"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BAlert,
    CustomVSelect,
    FlatPicker,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingError: null,
      buyerOptions: [],
      buyers: [],
      billDate: null,
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.setInteralState()
      },
    },
  },
  created() {
    this.setInteralState()
  },
  mounted() {
    this.fetchOptions()
  },
  methods: {
    setInteralState() {
      this.buyers = this.filters.buyers
      this.billDate = this.filters.billDate
    },
    async fetchOptions() {
      this.loading = true
      try {
        const { data: buyers } = await axios.get('/accounts/buyers_options')
        this.buyerOptions = buyers.data
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Failed to fetch filter options!'
      } finally {
        this.loading = false
      }
    },
    filtersChangeHandler() {
      const filtersdata = {}
      filtersdata.buyers = this.buyers
      filtersdata.billDate = this.billDate

      this.$emit('filtersUpdated', filtersdata)
    },
  },

}
</script>
