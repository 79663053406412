<template>
  <b-card no-body>
    <b-card-header>
      <h4>Packing</h4>
    </b-card-header>
    <b-card-body>
      <packing-filters
        :filters="filters"
        @filtersUpdated="filtersChangeHandler"
      />
      <div class="text-right mb-1">
        <b-button
          variant="outline-primary"
          :disabled="loading"
          class="refresh-btn"
          :class="isNewItemAvailable ? 'pr-3' : ''"
          @click="fetchData"
        >
          Refresh
          <span
            v-if="isNewItemAvailable"
            class="badge badge-up badge-pill badge-success badge-minimal badge-glow"
          />
        </b-button>
      </div>

      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner
          label="Small Spinner"
          variant="primary"
        />
      </div>

      <b-alert
        variant="danger"
        :show="!loading && loadingError ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ loadingError }}
          </p>
        </div>
      </b-alert>

      <div v-if="!loading && !loadingError">
        <b-table
          responsive
          striped
          hover
          :items="tableItems"
          :fields="tableFields"
          class="compact-table"
          no-local-sorting
          show-empty
          empty-text="No Records to display!"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="sortingChanged"
        />
        <detailed-pagination
          :total-items="totalItems"
          :per-page="perPage"
          :current-page="currentPage"
          @page-changed="handlePageChange"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from 'axios'
import {
  BTable, BCard, BCardBody, BCardHeader, BSpinner, BAlert, BButton,
} from 'bootstrap-vue'
import getParsedQueryString from '@/utils/headerQueryParser'

import PackingFilters from '@/components/Operations/Packing/PackingFilters.vue'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import dateMixin from '@/mixins/dateMixin'

import WS, { WS_ROOMS, WS_EVENTS } from '@/utils/ws'
import bus from '@/bus'

export default {
  components: {
    BTable,
    BCard,
    BCardBody,
    BCardHeader,
    BSpinner,
    BAlert,
    BButton,
    DetailedPagination,
    PackingFilters,
  },
  mixins: [dateMixin],
  data() {
    return {
      filters: {
        buyers: [],
        billDate: `${this.formatedDate(new Date(), 'YYYY-MM-DD')} to ${this.formatedDate(new Date(), 'YYYY-MM-DD')}`,
      },
      perPage: 50,
      currentPage: 1,
      totalItems: 0,
      tableItems: [],
      loading: false,
      loadingError: null,
      tableFields: [
        { key: 'identifier', label: 'Party', sortable: true },
        { key: 'created', label: 'Created Orders', sortable: true },
        { key: 'ready_for_packing', label: 'Ready For Packing Orders', sortable: true },
        { key: 'packing_initiated', label: 'Packing Initiated Orders', sortable: true },
        { key: 'packing_completed', label: 'Packing Completed Orders', sortable: true },
      ],
      sortBy: 'ready_for_packing',
      sortDesc: true,
      isNewItemAvailable: false,
    }
  },
  watch: {
    filters() {
      this.currentPage = 1
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
    WS.joinRoom(WS_ROOMS.RG_REPORT_STATUS)
    WS.joinRoom(WS_ROOMS.BILL_RG_STATUS)
    bus.$on(WS_EVENTS.RG_REPORT_CREATED, this.onBackendDataUpdated)
    bus.$on(WS_EVENTS.RG_REPORT_UPDATED, this.onBackendDataUpdated)
    bus.$on(WS_EVENTS.BILL_RG_STATUS_CREATED, this.onBackendDataUpdated)
    bus.$on(WS_EVENTS.BILL_RG_STATUS_UPDATED, this.onBackendDataUpdated)
  },
  destroyed() {
    WS.leaveRoom(WS_ROOMS.RG_REPORT_STATUS)
    WS.leaveRoom(WS_ROOMS.BILL_RG_STATUS)
    bus.$off(WS_EVENTS.RG_REPORT_CREATED, this.onBackendDataUpdated)
    bus.$off(WS_EVENTS.RG_REPORT_UPDATED, this.onBackendDataUpdated)
    bus.$off(WS_EVENTS.BILL_RG_STATUS_CREATED, this.onBackendDataUpdated)
    bus.$off(WS_EVENTS.BILL_RG_STATUS_UPDATED, this.onBackendDataUpdated)
  },
  methods: {
    fetchData() {
      this.loading = true

      const params = {
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        page: this.currentPage,
        per_page: this.perPage,
        buyers: this.filters.buyers,
      }

      const { billDate } = this.filters
      if (billDate) {
        const [fromDate, toDate] = billDate.split(' to ')
        if (fromDate && toDate) {
          params.date_from = fromDate
          params.date_to = toDate
        }
      }

      axios.get(`/operations/party-orders-info?${getParsedQueryString(params)}`)
        .then(res => {
          const responseData = res.data
          this.totalItems = responseData.count
          this.tableItems = responseData.results
          this.loadingError = null
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Error fetching orders info'
        })
        .finally(() => {
          this.loading = false
          this.isNewItemAvailable = false
        })
    },
    onBackendDataUpdated() {
      this.isNewItemAvailable = true
    },
    sortingChanged(ctx) {
      if (this.tableItems.length < 1) {
        return
      }
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchData()
    },
    handlePageChange(page) {
      this.currentPage = page
      this.fetchData()
    },
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}

</script>

<style lang="scss" scoped>
.refresh-btn {
  position: relative;

  .badge {
    height: 9px;
    width: 9px;
    min-width: 1px;
    min-height: 1px;
    right: 17px;
    top: 14px;
  }
}
</style>
