const getParsedQueryString = (params = {}) => {
  const urlParams = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        if (item) {
          urlParams.append(key, item)
        }
      })
    } else if (value) {
      urlParams.append(key, value)
    }
  })

  return urlParams.toString()
}

export default getParsedQueryString
